import {

  // Generic Pages
  Blog,
  Directories,
  Adverts,
  Events,
  Forum,
  Services,
  Cart,

  // Auth Pages
  SignIn,
  SignUp,
  ForgotPassword,

  // Navbar Overlays
  HomeOverlay,
  AboutOverlay,
  ServicesOverlay,

  // User Pages
  UserHome,
  FieldServices,
  FarmerData,
  MediaServices,
  GroupServices,
  Trading,
  RecordManagement,
  TechnicalServices,
  LoansGrants,
  About,
  Contact,
  Pricing,
  Media,
} from "../../pages";

import {
  UserAdverts,
  UserDirectories,
  UserEvents,
  UserForum,
  FrFarmerRecord,
  FrGroups,
  FrMessaging,
  FsAggregation,
  FsOfftake,
  FsProjects,
  FsPrograms,
  GsCooperatives,
  GsManageCooperative,
  GsCooperativeDues,
  GsCooperativeMembers,
  GsManageCooperativeMember,
  GsAssociations,
  GsManageAssociation,
  GsAssociationDues,
  GsAssociationMembers,
  FarmerDashboard,
  MediaDashboard,
  FsAnchorBrowser,
  FsAnchorBorrower,
  FsDashboard,
  GsDashboard,
  LoanDashboard,
  Loans,
  LoanRequest,
  Grant,
  GrantRequest,
  TDashboard,
  MarketPlace,
  PostBuyLeads,
  PostSellLeads,
  Promotion,
  AddCustomer,
  AddProduct,
  AddSupplier,
  Inventory,
  Processing,
  Purchase,
  Reports,
  Sales,
  TsDashboard,
} from "../../components";
import UserBlogs from "../../components/user/mediaServices/UserBlogs";

const navItems = [
  { title: "Home", path: "/", dropdown: null },
  { title: "About", path: "/about", dropdown: null },
  { title: "Services", path: "/services", dropdown: null },
  // { title: "Services", path: "/services", dropdown: <ServicesOverlay /> },
  { title: "Pricing", path: "/pricing", dropdown: null },
  { title: "Media", path: "/media", dropdown: null },
  // { title: "Media", path: "/media", dropdown: <AboutOverlay /> },
  { title: "Contact Us", path: "/contact", dropdown: null },
];

const appRoutes = [
  { path: "/blog", element: <Blog /> },
  { path: "/forum", element: <Forum /> },
  {
    path: "/directories",
    element: <Directories />,
  },
  { path: "/services", element: <Services /> },
  { path: "/adverts", element: <Adverts /> },
  { path: "/events", element: <Events /> },
  { path: "/about", element: <About /> },
  { path: "/contact", element: <Contact /> },
  { path: "/pricing", element: <Pricing /> },
  { path: "/media", element: <Media /> },
  { path: "/cart", element: <Cart /> },
  { path: "/auth/signin", element: <SignIn /> },
  { path: "/auth/forgot-password", element: <ForgotPassword /> },
  { path: "/auth/signup", element: <SignUp /> },
  { path: "/user/home", element: <UserHome /> },
  {
    path: "/user/farmer-data",
    element: <FarmerData />,
    children: [
      { path: "dashboard", element: <FarmerDashboard /> },
      { path: "farmer-record", element: <FrFarmerRecord /> },
      { path: "groups", element: <FrGroups /> },
      { path: "messaging", element: <FrMessaging /> },
    ],
  },
  {
    path: "/user/media-services",
    element: <MediaServices />,
    children: [
      { path: "dashboard", element: <MediaDashboard /> },
      { path: "blog", element: <UserBlogs /> },
      { path: "forum", element: <UserForum /> },
      { path: "directories", element: <UserDirectories /> },
      { path: "adverts", element: <UserAdverts /> },
      { path: "events", element: <UserEvents /> },
    ],
  },
  {
    path: "/user/field-services",
    element: <FieldServices />,
    children: [
      { path: "dashboard", element: <FsDashboard /> },
      { path: "aggregation", element: <FsAggregation /> },
      { path: "offtake", element: <FsOfftake /> },
      { path: "anchor-Borrower", element: <FsAnchorBorrower /> },
      { path: "projects", element: <FsProjects /> },
      { path: "programs", element: <FsPrograms /> },
    ],
  },
  {
    path: "/user/group-services",
    element: <GroupServices />,
    children: [
      { path: "dashboard", element: <GsDashboard /> },
      { path: "cooperatives", element: <GsCooperatives /> },
      { path: "cooperatives/manage", element: <GsManageCooperative /> },
      { path: "cooperatives/dues", element: <GsCooperativeDues /> },
      { path: "cooperatives/members", element: <GsCooperativeMembers /> },
      { path: "cooperatives/manage-members", element: <GsManageCooperativeMember /> },
      { path: "associations", element: <GsAssociations /> },
      { path: "associations/manage", element: <GsManageAssociation /> },
      { path: "associations/dues", element: <GsAssociationDues /> },
      { path: "associations/members", element: <GsAssociationMembers /> },
    ],
  },
  {
    path: "/user/trading",
    element: <Trading />,
    children: [
      { path: "dashboard", element: <TDashboard /> },
      { path: "market-place", element: <MarketPlace /> },
      { path: "post-buy-leads", element: <PostBuyLeads /> },
      { path: "post-sell-leads", element: <PostSellLeads /> },
      { path: "promotion", element: <Promotion /> }
    ],
  },


  {
    path: "/user/records",
    element: <RecordManagement />,
    children: [
      { path: "dashboard", element: <TDashboard /> },
      { path: "add-customer", element: <AddCustomer /> },
      { path: "add-product", element: <AddProduct /> },
      { path: "add-supplier", element: <AddSupplier /> },
      { path: "inventory", element: <Inventory /> },
      { path: "processing", element: <Processing /> },
      { path: "purchase", element: <Purchase /> },
      { path: "reports", element: <Reports /> },
      { path: "sales", element: <Sales /> },

    ],
  },


  {
    path: "/user/technical-services",
    element: <TechnicalServices />,
    children: [
      { path: "dashboard", element: <TsDashboard /> },
    ]
  },


  {
    path: "/user/loans-and-grants", element:
      <LoansGrants />,
    children: [
      { path: "dashboard", element: <LoanDashboard /> },
      { path: "loans", element: <Loans /> },
      { path: "loan-request", element: <LoanRequest /> },
      { path: "grants", element: <Grant /> },
      { path: "grant-request", element: <GrantRequest /> }

    ],

  },
];

const dropdownItems = [
  { title: "Sub Menu 1", path: "/submenu1" },
  { title: "Sub Menu 2", path: "/submenu1" },
  { title: "Sub Menu 3", path: "/submenu1" },
];

const userHome = "/user/home";

export { appRoutes, navItems, dropdownItems, userHome };
